//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-824:_2656,_1264,_4836,_6464,_1809,_4332,_3640,_6421;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-824')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-824', "_2656,_1264,_4836,_6464,_1809,_4332,_3640,_6421");
        }
      }catch (ex) {
        console.error(ex);
      }